// extracted by mini-css-extract-plugin
export var caseContactFormSection = "bF_k3";
export var caseStudyBackground__lineColor = "bF_k0";
export var caseStudyHead = "bF_kW";
export var caseStudyHead__imageWrapper = "bF_kX";
export var caseStudyProjectsSection = "bF_k4";
export var caseStudyQuote__bgRing = "bF_kZ";
export var caseStudyVideo__ring = "bF_k2";
export var caseStudy__bgDark = "bF_kV";
export var caseStudy__bgDarkReverse = "bF_mc";
export var caseStudy__bgLight = "bF_kT";